import './index.css';
import Interface from "../../../src/assets/interface.png";


const Background = () => {
    return (
        <div className="back">
          <img src={ Interface }/> 
        </div>
      );
};
export default Background;